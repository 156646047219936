import React, { useState } from "react";
import Box from "ReusableComponents/Box";
import { Grid, Modal, IconButton } from "@mui/material";
import {
  Close as CloseIcon,
  NavigateBefore as PrevIcon,
  NavigateNext as NextIcon,
} from "@mui/icons-material";

const GalleryImageView = ({ pics, small }) => {
  const images = pics?.attributes?.gallery?.data.map(
    (item) => item?.attributes?.url
  );
  const imagesAltText = pics?.attributes?.gallery?.data.map(
    (item) => item?.attributes?.alternativeText
  );

  const [isHovered, setIsHovered] = useState(Array(4).fill(false));
  const [selectedImage, setSelectedImage] = useState(null);

  const handleMouseEnter = (index) => {
    const newIsHovered = [...isHovered];
    newIsHovered[index] = true;
    setIsHovered(newIsHovered);
  };

  const handleMouseLeave = () => {
    const newIsHovered = Array(4).fill(false);
    setIsHovered(newIsHovered);
  };

  const hoverStyles = (index) => ({
    transform: isHovered[index] ? "scale(1.1)" : "scale(1)",
    transition: "transform 0.1s ease",
  });

  const nextImage = () => {
    if (selectedImage !== null && selectedImage < images.length - 1) {
      setSelectedImage(selectedImage + 1);
    }
  };

  const prevImage = () => {
    if (selectedImage !== null && selectedImage > 0) {
      setSelectedImage(selectedImage - 1);
    }
  };

  const openFullScreen = (index) => {
    setSelectedImage(index);
  };

  const closeFullScreen = () => {
    setSelectedImage(null);
  };

  return (
    <Box
      textAlign="center"
      display="flex"
      margin={small && "20px 0"}
      flexDirection="column"
      padding={small ? 0 : "0 80px"}
    >
      {images && (
        <>
          <Grid row display="flex">
            <Grid contaier display="flex">
              <Grid item style={{ display: "flex" }}>
                <Box padding="5px">
                  <div
                    style={{
                      height: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={images[0]}
                      style={{
                        width: "100%",
                        height: "100%",
                        maxHeight: "100%",
                        ...hoverStyles(0),
                      }}
                      alt={imagesAltText[0]}
                      onMouseEnter={() => handleMouseEnter(0)}
                      onMouseLeave={() => handleMouseLeave()}
                      onClick={() => openFullScreen(0)}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>

            <Grid contaier display="flex">
              <Grid item style={{ display: "flex", flexDirection: "column" }}>
                <Box padding="5px">
                  <div
                    style={{
                      height: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={images[1]}
                      style={{
                        width: "100%",
                        maxHeight: "100%",
                        ...hoverStyles(1),
                      }}
                      alt={imagesAltText[1]}
                      onMouseEnter={() => handleMouseEnter(1)}
                      onMouseLeave={() => handleMouseLeave()}
                      onClick={() => openFullScreen(1)}
                    />
                  </div>
                </Box>
                <Grid item style={{ display: "flex", flexDirection: "row" }}>
                  <Box padding="5px">
                    <div
                      style={{
                        height: "100%",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={images[2]}
                        style={{
                          maxWidth: "100%",
                          height: "100%",
                          ...hoverStyles(2),
                        }}
                        alt={imagesAltText[2]}
                        onMouseEnter={() => handleMouseEnter(2)}
                        onClick={() => openFullScreen(2)}
                        onMouseLeave={() => handleMouseLeave()}
                      />
                    </div>
                  </Box>
                  <Box padding="5px">
                    <div
                      style={{
                        height: "100%",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={images[3]}
                        style={{
                          maxWidth: "100%",
                          height: "100%",
                          ...hoverStyles(3),
                        }}
                        alt={imagesAltText[3]}
                        onMouseEnter={() => handleMouseEnter(3)}
                        onMouseLeave={() => handleMouseLeave()}
                        onClick={() => openFullScreen(3)}
                      />
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid contaier display="flex">
              <Grid item style={{ display: "flex" }}>
                <Box padding="5px">
                  <div
                    style={{
                      height: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={images[4]}
                      style={{
                        width: "100%",
                        maxHeight: "100%",
                        height: "100%",

                        ...hoverStyles(4),
                      }}
                      alt={imagesAltText[4]}
                      onMouseEnter={() => handleMouseEnter(4)}
                      onMouseLeave={() => handleMouseLeave()}
                      onClick={() => openFullScreen(4)}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Modal
            open={selectedImage !== null}
            onClose={closeFullScreen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "white",
                boxShadow: 24,
                p: 2,
                borderRadius: "4px",
                display: "flex",
                flexDirection: "column",

                alignItems: "center",
              }}
            >
              <IconButton
                onClick={prevImage}
                sx={{
                  position: "absolute",
                  left: "0",
                  top: "50%",
                  height: "20%",
                  color: "white",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                }}
              >
                <PrevIcon style={{ width: "5vw", height: "10vh" }} />
              </IconButton>
              <IconButton
                onClick={closeFullScreen}
                sx={{
                  position: "absolute",
                  right: small ? "2%" : "9%",
                  top: small ? "0%" : "-3%",

                  color: "white",
                  zIndex: 1,
                }}
              >
                <CloseIcon />
              </IconButton>
              <img
                src={images[selectedImage]}
                alt={imagesAltText[selectedImage]}
                style={{ maxWidth: "100%", maxHeight: "80vh" }}
              />
              <IconButton
                onClick={nextImage}
                sx={{
                  position: "absolute",
                  right: "0",
                  top: "42%",
                  color: "white",
                  zIndex: 1,
                }}
              >
                <NextIcon style={{ width: "5vw", height: "10vh" }} />
              </IconButton>
            </Box>
          </Modal>
        </>
      )}
    </Box>
  );
};
export default GalleryImageView;
