import { Container } from "@mui/system";
import { QueryClient, QueryClientProvider } from "react-query";
import { NavLinks } from "routes";
import React, { useEffect, useState } from "react";
import "./App.css";
import AlertRefresh from "ReusableComponents/customalert/AlertRefresh";
import "./i18n";
import { LanguageProvider } from "LanguageContext";
import { useTranslation } from "react-i18next";

const App = () => {
  const [isNewVersion, setIsNewVersion] = useState(false);
  const { i18n } = useTranslation();

  setCanonicalAndHreflangLinks();
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const utm_medium = queryParams.get("utm_medium");
    const utm_source = queryParams.get("utm_source");
    const utm_campaign = queryParams.get("utm_campaign");
    const gclid = queryParams.get("gclid");
    if (utm_medium || utm_source || utm_campaign || gclid) {
      sessionStorage.setItem("utm_medium", utm_medium);
      sessionStorage.setItem("utm_source", utm_source);
      sessionStorage.setItem("utm_campaign", utm_campaign);
      sessionStorage.setItem("gclid", gclid);
    }
    localStorage.setItem("appVersion", process.env.REACT_APP_VERSION);
    const storedVersion = localStorage.getItem("appVersion");
    const currentVersion = process.env.REACT_APP_VERSION;
    if (storedVersion !== currentVersion) {
      setIsNewVersion(true);
    }
  }, []);

  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);
  const queryClient = new QueryClient();

  return (
    <LanguageProvider>
      <Container style={{ maxWidth: 1800, padding: 0, position: "relative" }}>
        {isNewVersion && <AlertRefresh />}
        <QueryClientProvider client={queryClient}>
          <NavLinks />
        </QueryClientProvider>
      </Container>
    </LanguageProvider>
  );
};

const setCanonicalAndHreflangLinks = () => {
  const { origin, pathname } = window.location;

  // Extract the language code from the pathname
  const pathSegments = pathname.split("/");
  const currentLang = pathSegments[1];
  const slug = pathSegments.slice(2).join("/"); // Adjusting to exclude language segment

  const languages = [
    { value: "en", name: "English" },
    { value: "hi", name: "हिंदी . Hindi" },
    { value: "ta", name: "தமிழ் . Tamil" },
    { value: "te", name: "తెలుగు . Telugu" },
    { value: "mr", name: "मराठी . Marathi" },
    { value: "gu", name: "ગુજરાતી . Gujarati" },
    { value: "kn", name: "ಕನ್ನಡ . Kannada" },
    { value: "bn", name: "Bengali" },
  ];

  // Set canonical link
  let canonicalLink = document.querySelector('link[rel="canonical"]');
  if (!canonicalLink) {
    canonicalLink = document.createElement("link");
    canonicalLink.rel = "canonical";
    document.head.appendChild(canonicalLink);
  }
  canonicalLink.href = `${origin}/${currentLang}/${slug}`;

  // Set hreflang links for all languages except the current language
  languages.forEach((lang) => {
    if (lang.value !== currentLang) {
      // Check if it's not the current language
      let link = document.querySelector(
        `link[rel="alternate"][hreflang="${lang.value}"]`
      );
      if (!link) {
        link = document.createElement("link");
        link.rel = "alternate";
        link.hreflang = lang.value;
        document.head.appendChild(link);
      }
      link.href = `${origin}/${lang.value}/${slug}`;
    }
  });
};

// Call the function to set canonical and hreflang links
setCanonicalAndHreflangLinks();

export default App;
