import { Grid } from "@mui/material";
import React from "react";
import Box from "ReusableComponents/Box";
import { COLORS, SIZES, WEIGHT } from "theme";
import ErrorSVG from "assets/error.svg";
import RightPath from "assets/rightPath.svg";

import Text from "ReusableComponents/Text";

const Error500 = () => {
  return (
    <Grid item xs={12} style={{ padding: 0 }}>
      <Box
        background={COLORS.lightBlack}
        padding={"80px"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Text
          fontSize={"120px"}
          fontWeight={WEIGHT.regular}
          margin={SIZES.twentyFour}
          textColor={COLORS.white}
        >
          500
        </Text>
        <Box display="flex" alignItems="center" justifyContent="center">
          <img src={ErrorSVG} alt="error" />
        </Box>
        <Text
          fontSize={SIZES.forty}
          fontWeight={WEIGHT.regular}
          margin={`${SIZES.forty} 0`}
          textColor={COLORS.white}
        >
          Internal Server Error
        </Text>
        <Box
          className="btn btn-primary"
          background={COLORS.primary}
          display="flex"
          alignItems="center"
          padding="0 24px"
          justifyContent="center"
          cursor="pointer"
          onClick={() => window.location.reload()}
        >
          <Text fontSize="16px" fontWeight="bold" textColor={COLORS.black}>
            Refresh
          </Text>
          <img
            src={RightPath}
            alt="rightPath"
            style={{ margin: "0 8px" }}
            height="16px"
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default Error500;
