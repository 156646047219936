import React, { useEffect, useState } from "react";
import Box from "ReusableComponents/Box";
import Text from "ReusableComponents/Text";
import { COLORS, SIZES } from "theme";
import RightPathBlack from "assets/rightpathBlack.png";
import { useMediaQuery } from "@mui/material";

const ScrollToTop = () => {
  const small = useMediaQuery("(max-width: 768px)");
  const [show, setShow] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 100) {
      setShow(true);
    } else {
      setShow(false);
    }
  });

  return (
    <Box
      position="fixed"
      onClick={() => window.scrollTo(0, 0)}
      bottom={small ? "80px" : "100px"}
      zIndex="6"
      cursor="pointer"
      right={small ? "20px" : "80px"}
      display="flex"
      alignItems="center"
      flexDirection="column"
      opacity={show ? 1 : 0}
      transition="all 0.4s"
    >
      <Box
        h="40px"
        w="40px"
        borderRadius="50%"
        background={COLORS.primary}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={RightPathBlack}
          alt="top"
          style={{ margin: "0 8px", transform: "rotate(270deg)" }}
          height="25px"
        />
      </Box>
    </Box>
  );
};

export default ScrollToTop;
