import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";

const withLanguageRedirect = (WrappedComponent) => {
  return (props) => {
    const { i18n } = useTranslation();
    const currentLang = i18n.language;

    // Check if the URL already contains the language prefix or '/en' specifically
    const enRegex = /^\/en(\/|$)/;
    const langRegex = new RegExp(`^/${currentLang}(/|$)`);

    if (
      !enRegex.test(props.location.pathname) &&
      !langRegex.test(props.location.pathname)
    ) {
      return <Redirect to={`/${currentLang}${props.location.pathname}`} />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withLanguageRedirect;
