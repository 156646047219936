import { Grid } from "@mui/material";
import React from "react";
import Box from "ReusableComponents/Box";
import Text from "ReusableComponents/Text";
import H2 from "ReusableComponents/Text/H2";
import H3 from "ReusableComponents/Text/H3";
import Span from "ReusableComponents/Text/Span";
import { COLORS, SIZES, WEIGHT } from "theme";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ServiceCommitment = ({
  img,
  name,
  description,
  features,
  color,
  fromCharging,
  subtitle,
  isHiload,
  id,
  small,
  hideIcons,
  fromCareer,
  medium,
}) => {
  return (
    <>
      <Box
        h={small ? "400px" : "589px"}
        w="100%"
        background={`url(${
          !small ? (isHiload ? img : img) : fromCareer ? img : img
        })`}
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          display: "flex",
          backgroundPosition: "center",
        }}
        position="relative"
        paddingTop={fromCharging && "80px"}
        id={id}
        zIndex={1}
      >
        <Box
          background="linear-gradient(to right, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%)"
          display="flex"
          justifyContent={"start"}
          alignItems="end"
          w="inherit"
          textAlign={"left"}
        >
          <Box margin={small ? "20px" : "40px 80px"}>
            <Box padding={small ? SIZES.twelve : "6px 24px"} w="fit-content">
              <Box marginBottom={small ? 0 : "2rem"}>
                <H3
                  w="fit-content"
                  display="flex"
                  flexDirection="column"
                  marginBottom={small ? SIZES.twelve : SIZES.twenty}
                >
                  <Span
                    fontSize={small ? SIZES.twenty : SIZES.forty}
                    w="fit-content"
                    textAlign="center"
                    textColor="white"
                    background={COLORS.black}
                    fontWeight="bold"
                    padding={small ? "0.2em" : "0.3em"}
                  >
                    {name}
                  </Span>

                  <Span
                    fontSize={small ? SIZES.twenty : SIZES.forty}
                    padding={small ? "0.2em" : "0.3em"}
                    background={COLORS.black}
                    textColor="white"
                    w="fit-content"
                    fontWeight="bold"
                    textAlign="center"
                    marginLeft="1em"
                  >
                    {subtitle}
                  </Span>
                </H3>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box background={COLORS.lightBlack}>
        <Grid
          container
          spacing={2}
          style={{ padding: small ? "40px" : "40px 80px 80px 80px" }}
        >
          <Grid item xs={12} style={{ paddingTop: 30 }}>
            <Box display="flex" flexDirection="column" textAlign="left">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="start"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Box
                      display="flex"
                      flexDirection={"column"}
                      marginLeft={small || medium ? 0 : "40px"}
                    >
                      <Box
                        display="flex"
                        justifyContent="start"
                        alignItems="center"
                        marginBottom={small || medium ? "20px" : "48px"}
                      >
                        <img
                          src={features[0].img}
                          alt={features[0].alt}
                          style={{ marginRight: 12 }}
                        />
                        <Text
                          fontSize={small ? "12px" : "20px"}
                          textColor={color ? color : "#707070"}
                          margin="0"
                          width="217px"
                        >
                          {features[0].title}
                        </Text>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <Box
                      display="flex"
                      flexDirection={"column"}
                      marginLeft={small || medium ? 0 : "40px"}
                    >
                      <Box
                        display="flex"
                        justifyContent="start"
                        alignItems="center"
                        marginBottom={small || medium ? "20px" : "48px"}
                      >
                        <img
                          src={features[1].img}
                          alt={features[1].alt}
                          style={{ marginRight: 12 }}
                        />
                        <Text
                          fontSize={small ? "12px" : "20px"}
                          textColor={color ? color : "#707070"}
                          margin="0"
                          width="217px"
                        >
                          {features[1].title}
                        </Text>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Box
                      display="flex"
                      flexDirection={"column"}
                      marginLeft={small || medium ? 0 : "40px"}
                    >
                      <Box
                        display="flex"
                        justifyContent="start"
                        alignItems="center"
                        marginBottom={small || medium ? "20px" : "48px"}
                      >
                        <img
                          src={features[2].img}
                          alt={features[2].alt}
                          style={{ marginRight: 12 }}
                        />
                        <Text
                          fontSize={small ? "12px" : "20px"}
                          textColor={color ? color : "#707070"}
                          margin="0"
                          width="217px"
                        >
                          {features[2].title}
                        </Text>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ServiceCommitment;
