import { Grid, Divider } from "@mui/material";
import IconHolder from "Pages/ShowroomNetwork/components/IconHolder";
import React, { useState } from "react";
import Box from "ReusableComponents/Box";
import Text from "ReusableComponents/Text";
import { SIZES } from "theme";
import "./style.css";

const ServiceOptions = ({ option, small, medium }) => {
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);

  return (
    <Box
      textAlign="center"
      display="flex"
      margin={small && "20px 0"}
      flexDirection="column"
      padding={small ? 0 : "0 80px"}
    >
      <Text
        textColor="white"
        fontWeight="bold"
        fontSize={small ? SIZES.twentyFour : "40px"}
      >
        {option?.attributes?.heading}
      </Text>

      <Box display="flex" justifyContent="space-between">
        <Grid
          option
          xs={12}
          sm={6}
          key={0}
          onMouseEnter={() => setHover1(true)}
          onMouseLeave={() => setHover1(false)}
        >
          <div
            style={
              hover1
                ? {
                    position: "relative",
                    backgroundColor: "black",
                    width: "100%",
                    height: "100%",
                    opacity: "0.8",
                  }
                : {}
            }
          >
            {hover1 && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 20,
                  right: 20,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                }}
              >
                <span>{option?.attributes?.option1description}</span>
              </div>
            )}

            <IconHolder
              style={{
                position: "relative",
                zIndex: 1,
              }}
              hover={hover1}
              small={small}
              Icon={option?.attributes?.option1image?.data?.attributes?.url}
              name={option?.attributes?.option1text}
            />
            <br />
            <br />
          </div>
        </Grid>

        <div className="vertical-divider" />
        <Grid
          option
          xs={12}
          sm={6}
          key={1}
          onMouseEnter={() => setHover2(true)}
          onMouseLeave={() => setHover2(false)}
        >
          <div
            style={
              hover2
                ? {
                    position: "relative",
                    backgroundColor: "black",
                    width: "100%",
                    height: "100%",
                    opacity: "0.8",
                  }
                : {}
            }
          >
            {hover2 && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 20,
                  right: 20,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                }}
              >
                <span>{option?.attributes?.option2description}</span>
              </div>
            )}

            <IconHolder
              style={{
                position: "relative",
                zIndex: 1,
              }}
              hover={hover2}
              small={small}
              Icon={option?.attributes?.option2image?.data?.attributes?.url}
              name={option?.attributes?.option2text}
            />
          </div>
        </Grid>
      </Box>
      <Divider sx={{ bgcolor: "grey", height: "0.5px" }} />

      <Box display="flex" justifyContent="space-between">
        <Grid
          option
          xs={12}
          sm={6}
          key={0}
          onMouseEnter={() => setHover3(true)}
          onMouseLeave={() => setHover3(false)}
        >
          <div
            style={
              hover3
                ? {
                    position: "relative",
                    backgroundColor: "black",
                    width: "100%",
                    height: "100%",
                    opacity: "0.8",
                  }
                : {}
            }
          >
            {hover3 && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 20,
                  right: 20,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                }}
              >
                <span>{option?.attributes?.option3description}</span>
              </div>
            )}
            <br />
            <br />
            <IconHolder
              style={{
                position: "relative",
                zIndex: 1,
              }}
              small={small}
              hover={hover3}
              Icon={option?.attributes?.option3image?.data?.attributes?.url}
              name={option?.attributes?.option3text}
            />
          </div>
        </Grid>

        <div className="vertical-divider" />
        <Grid
          option
          xs={12}
          sm={6}
          key={1}
          onMouseEnter={() => setHover4(true)}
          onMouseLeave={() => setHover4(false)}
        >
          <div
            style={
              hover4
                ? {
                    position: "relative",
                    backgroundColor: "black",
                    width: "100%",
                    height: "100%",
                    opacity: "0.8",
                  }
                : {}
            }
          >
            {hover4 && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 20,
                  right: 20,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                }}
              >
                <span>{option?.attributes?.option4description}</span>
              </div>
            )}
            <br />
            <br />
            <IconHolder
              style={{
                position: "relative",
                zIndex: 1,
              }}
              small={small}
              hover={hover4}
              Icon={option?.attributes?.option4image?.data?.attributes?.url}
              name={option?.attributes?.option4text}
            />
          </div>
        </Grid>
      </Box>
    </Box>
  );
};

export default ServiceOptions;
