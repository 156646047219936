import React from "react";
import Box from "ReusableComponents/Box";
import { WEIGHT } from "theme";
import Text from "ReusableComponents/Text";
import SmallIconLabel from "./SmallIconLabel";
import ViewOnMap from "assets/viewonmap.svg";
import Direction from "assets/location.svg";

const LocationBox = ({ name, place, lat, long }) => {
  return (
    <Box minW="376px" background={"#EFEFEF"} padding="16px">
      <Box display="flex" flexDirection="column">
        <Text fontWeight={WEIGHT.bold} fontSize={"14px"} margin="0">
          {name}
        </Text>
        <Text fontWeight={WEIGHT.regular} fontSize={"16px"} maxWidth="319px">
          {place}
        </Text>
      </Box>
      <Box display="flex" justifyContent="end">
        <SmallIconLabel
          label={"View on Map"}
          img={ViewOnMap}
          lat={lat}
          long={long}
        />
        <SmallIconLabel
          label={"Get Direction"}
          img={Direction}
          paddingRight="10px"
          paddingTop="10px"
          isMargin
          lat={lat}
          long={long}
        />
      </Box>
    </Box>
  );
};

export default LocationBox;
