import React, { useState } from "react";
import Box from "ReusableComponents/Box";
import Tabs from "Pages/Hiload/components/VehicleViewTabs/Tabs";
import Text from "ReusableComponents/Text";
import { SIZES } from "theme";

const StoreLocator = ({ small, medium, headings, text }) => {
  const [active, setActive] = useState(1);
  const getActiveIndex = (index) => {
    setActive(index);
  };
  return (
    <Box padding={small ? SIZES.twenty : "80px 12px"}>
      <Tabs
        small={small}
        active={active}
        w="381px"
        fromHiload
        isBottomBorder
        tabs={headings}
        getActive={getActiveIndex}
      />
      <Box display="flex" padding={small ? 0 : "0 80px"}>
        {text?.map((item) => (
          <Box
            margin={small ? "20px 0 " : "25px auto auto 0"}
            textAlign="left"
            key={item.id}
            w={small ? "100%" : "381px"}
            display={
              small
                ? item?.attributes?.order === active + 1
                  ? "flex"
                  : "none"
                : "flex"
            }
          >
            <Text
              fontSize={small ? SIZES.fourteen : "16px"}
              textColor="white"
              lineHeight="2"
              margin="0"
            >
              {item?.attributes?.text}
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default StoreLocator;
