import styled from "styled-components";
export const BoxStyled = styled.div`
  display: ${({ display }) => display};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  transform: ${({ transform }) => transform};
  cursor: ${({ cursor }) => cursor};
  padding: ${({ padding }) => padding};
  background-size: ${({ backgroundSize }) => backgroundSize};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  padding-top: ${({ paddingTop }) => paddingTop};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-top: ${({ marginTop }) => marginTop};
  margin: ${({ margin }) => margin};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-right: ${({ marginRight }) => marginRight};
  background-color: ${({ bgc }) => bgc};
  background-size: ${({ backgroundSize }) => backgroundSize};
  background: ${({ background }) => background};
  background-image: ${({ bgi }) => bgi};
  color: ${({ color }) => color};
  border-radius: ${({ borderRadius }) => borderRadius};
  border-top-right-radius: ${({ borderRadiusTopRight }) =>
    borderRadiusTopRight};
  border-bottom-right-radius: ${({ borderRadiusBottomRight }) =>
    borderRadiusBottomRight};
  cursor: ${({ pointer }) => pointer && "pointer"};
  border: ${({ b }) => b};
  pointer-events: ${({ ponterEvents }) => ponterEvents};
  border-bottom: ${({ bb }) => bb};
  border-top: ${({ bt }) => bt};
  border-right: ${({ br }) => br};
  border-left: ${({ bl }) => bl};
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  max-width: ${({ maxW }) => maxW};
  min-width: ${({ minW }) => minW};
  max-height: ${({ maxH }) => maxH};
  min-height: ${({ minH }) => minH};
  user-select: ${({ userSelect }) => userSelect};
  flex: ${({ flex }) => flex};
  overflow: ${({ overflow }) => overflow};
  overflow-x: ${({ overflowX }) => overflowX};
  overflow-y: ${({ overflowY }) => overflowY};
  text-align: ${({ textAlign }) => textAlign};
  text-decoration: ${({ textDecoration }) => textDecoration};
  transform: ${({ transform }) => transform};
  transition: ${({ transition }) => transition};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  box-shadow: ${({ boxShadow }) => boxShadow};
  z-index: ${({ zIndex }) => zIndex};
  opacity: ${({ opacity }) => opacity};
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  flex-basis: ${({ flexBasis }) => flexBasis};
  flex-grow: ${({ flexGrow }) => flexGrow};
  color: ${({ color }) => color};

  grid-auto-flow: ${({ gridAutoFlow }) => gridAutoFlow};
  column-gap: ${({ columnGap }) => columnGap};
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};

  ${({ hoverBackground }) =>
    hoverBackground &&
    `
    transition: all 0.7s ease; /* Added transition for hover effect */
    position: relative; /* Added position for ::before and ::after */
    overflow: hidden; /* Added overflow for ::before and ::after */
    z-index: 1; /* Added z-index for ::before and ::after */
  
    &:hover {
      color: var(--dark) !important;
      background-color: #99CEFB;
      border: solid 1px #99CEFB;
    }
  
    &:before,
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 50%;
      height: 120%;
      background-color: #99CEFB;
      transform: scale(2) rotate(12deg);
      width: 0%;
      transition: all 0.7s;
    }
  
    &:hover:before,
    &:hover:after {
      width: 100%;
      left: 0;
    }
    `}
`;
