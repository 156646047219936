import React from "react";

const MetaTags = ({ link }) => {
  return (
    <>
      <link rel="publisher" href={link} />;
    </>
  );
};

export default MetaTags;
