import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from "./locales/en.json";
import hiTranslation from "./locales/hi.json";
import knTranslation from "./locales/kn.json";
import taTranslation from "./locales/ta.json";
import teTranslation from "./locales/te.json";
import mrTranslation from "./locales/mr.json";
import guTranslation from "./locales/gu.json";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)

  .init({
    resources: {
      en: { translation: enTranslation },
      hi: { translation: hiTranslation },
      kn: { translation: knTranslation },
      ta: { translation: taTranslation },
      te: { translation: teTranslation },
      mr: { translation: mrTranslation },
      gu: { translation: guTranslation },
    },
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
