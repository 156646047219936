import { Grid } from "@mui/material";
import React from "react";
import Box from "ReusableComponents/Box";
import Text from "ReusableComponents/Text";
import H3 from "ReusableComponents/Text/H3";
import { COLORS, SIZES, WEIGHT } from "theme";

const Tabs = ({
  active,
  fromProducts,
  paddingBottom,
  isBottom,
  tabs = [],
  getActive,
  isBottomBorder,
  fromHiload,
  isAround,
  notMargin,
  small,
  medium,
}) => {
  return (
    <Grid
      item
      xs={12}
      style={{
        borderBottom: isBottomBorder
          ? "1px solid rgba(211,211,211,0.22)"
          : "none",
        marginBottom: isBottom ? "48px" : 0,
      }}
    >
      <Box
        display="flex"
        justifyContent={isAround ? "space-around" : "space-between"}
        margin={`0 ${notMargin || small ? "auto" : "80px"}`}
      >
        {tabs.map((item, i) => (
          <Box
            key={item?.id}
            borderLeft="none"
            w={fromHiload ? (small ? "auto" : "441px") : "auto"}
            borderRight="none"
            borderTop="none"
            bb={
              active === i
                ? `2px solid ${fromProducts ? COLORS.black : COLORS.primary}`
                : "none"
            }
            display="flex"
            alignItems="center"
            borderBottom={
              active === i ? "1px solid rgba(211,211,211,0.22)" : "none"
            }
            onClick={() => getActive(i)}
            paddingBottom={paddingBottom ? paddingBottom : "24px"}
          >
            <Box>
              {fromHiload ? (
                <H3
                  fontSize={small ? SIZES.twelve : "28px"}
                  fontWeight={WEIGHT.bold}
                  textColor={
                    active === i
                      ? fromProducts
                        ? COLORS.black
                        : COLORS.primary
                      : "white"
                  }
                  cursor="pointer"
                  margin="0"
                >
                  {item?.attributes?.heading}
                </H3>
              ) : (
                <Text
                  fontSize={small ? SIZES.twelve : "28px"}
                  fontWeight={WEIGHT.bold}
                  textColor={
                    active === i
                      ? fromProducts
                        ? COLORS.black
                        : COLORS.primary
                      : "white"
                  }
                  cursor="pointer"
                  margin="0"
                >
                  {item?.attributes?.heading}
                </Text>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Grid>
  );
};

export default Tabs;
