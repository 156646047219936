import styled from "styled-components";

export const TextStyled = styled.p`
  font-size: ${({ fontSize }) => fontSize};
  font-family: ${({ fontFamily = "Archia" }) => fontFamily};
  text-decoration: ${({ textDecoration }) => textDecoration};
  position: ${({ position }) => position};
  text-transform: ${({ textTransform }) => textTransform};
  cursor: ${({ cursor }) => cursor};
  padding-top: ${({ paddingTop }) => paddingTop};
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
  color: ${({ textColor }) => textColor};
  line-height: ${({ lineHeight }) => lineHeight};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${({ margin }) => margin};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  opacity: ${({ opacity }) => opacity};
  pointer-events: ${({ pointerEvents }) => pointerEvents};

  ${({ isMedia }) =>
    isMedia &&
    `
  @media (max-width: 768px) {
    font-size: ${isMedia};
  }
  `}
`;

export const TextStyledH1 = styled.h1`
  font-size: ${({ fontSize }) => fontSize};
  display: ${({ display }) => display};
  flex-direction: ${({ flexDirection }) => flexDirection};
  width: ${({ w }) => w};
  text-decoration: ${({ textDecoration }) => textDecoration};
  background-color: ${({ background }) => background};
  position: ${({ position }) => position};
  text-transform: ${({ textTransform }) => textTransform};
  cursor: ${({ cursor }) => cursor};
  padding-top: ${({ paddingTop }) => paddingTop};
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
  color: ${({ textColor }) => textColor};
  line-height: ${({ lineHeight }) => lineHeight};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${({ margin }) => margin};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  opacity: ${({ opacity }) => opacity};
  pointer-events: ${({ pointerEvents }) => pointerEvents};

  ${({ isMedia }) =>
    isMedia &&
    `
  @media (max-width: 768px) {
    font-size: ${isMedia};
  }
  `}
`;

export const TextStyledH2 = styled.h2`
  font-size: ${({ fontSize }) => fontSize};
  display: ${({ display }) => display};
  flex-direction: ${({ flexDirection }) => flexDirection};
  width: ${({ w }) => w};
  text-decoration: ${({ textDecoration }) => textDecoration};
  background-color: ${({ background }) => background};
  position: ${({ position }) => position};
  text-transform: ${({ textTransform }) => textTransform};
  cursor: ${({ cursor }) => cursor};
  padding-top: ${({ paddingTop }) => paddingTop};
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
  color: ${({ textColor }) => textColor};
  line-height: ${({ lineHeight }) => lineHeight};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${({ margin }) => margin};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  opacity: ${({ opacity }) => opacity};
  pointer-events: ${({ pointerEvents }) => pointerEvents};

  ${({ isMedia }) =>
    isMedia &&
    `
  @media (max-width: 768px) {
    font-size: ${isMedia};
  }
  `}
`;

export const TextStyledH3 = styled.h3`
  font-size: ${({ fontSize }) => fontSize};
  display: ${({ display }) => display};
  flex-direction: ${({ flexDirection }) => flexDirection};
  width: ${({ w }) => w};
  text-decoration: ${({ textDecoration }) => textDecoration};
  background-color: ${({ background }) => background};
  position: ${({ position }) => position};
  text-transform: ${({ textTransform }) => textTransform};
  cursor: ${({ cursor }) => cursor};
  padding-top: ${({ paddingTop }) => paddingTop};
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
  color: ${({ textColor }) => textColor};
  line-height: ${({ lineHeight }) => lineHeight};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${({ margin }) => margin};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  opacity: ${({ opacity }) => opacity};
  pointer-events: ${({ pointerEvents }) => pointerEvents};
`;

export const TextStyledSpan = styled.span`
  background-color: ${({ background }) => background};
  font-size: ${({ fontSize }) => fontSize};
  width: ${({ w }) => w};
  text-decoration: ${({ textDecoration }) => textDecoration};
  position: ${({ position }) => position};
  text-transform: ${({ textTransform }) => textTransform};
  text-align: ${({ textAlign }) => textAlign};
  cursor: ${({ cursor }) => cursor};
  padding-top: ${({ paddingTop }) => paddingTop};
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
  color: ${({ textColor }) => textColor};
  line-height: ${({ lineHeight }) => lineHeight};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${({ margin }) => margin};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  opacity: ${({ opacity }) => opacity};
  pointer-events: ${({ pointerEvents }) => pointerEvents};

  ${({ isMedia }) =>
    isMedia &&
    `
  @media (max-width: 768px) {
    font-size: ${isMedia};
  }
  `}
`;

export const TextStyledButton = styled.button`
  font-size: ${({ fontSize }) => fontSize};
  text-decoration: ${({ textDecoration }) => textDecoration};
  position: ${({ position }) => position};
  text-transform: ${({ textTransform }) => textTransform};
  cursor: ${({ cursor }) => cursor};
  padding-top: ${({ paddingTop }) => paddingTop};
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
  color: ${({ textColor }) => textColor};
  line-height: ${({ lineHeight }) => lineHeight};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${({ margin }) => margin};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  padding: ${({ padding }) => padding};
  border: ${({ border = "none" }) => border};
  opacity: ${({ opacity }) => opacity};
  pointer-events: ${({ pointerEvents }) => pointerEvents};

  ${({ isMedia }) =>
    isMedia &&
    `
  @media (max-width: 768px) {
    font-size: ${isMedia};
  }
  `}
`;
