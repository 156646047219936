import React from "react";
import Box from "ReusableComponents/Box";
import Text from "ReusableComponents/Text";
import { WEIGHT } from "theme";

const SmallIconLabel = ({ img, label, isPadding, isMargin, lat, long }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      marginLeft={isMargin && "12px"}
      cursor="pointer"
      onClick={() => {
        if (label === "View on Map")
          window.open(`https://www.google.com/maps/?q=${lat},${long}`);
        else {
          window.open(
            `https://www.google.com/maps/dir/?api=1&destination=${lat},${long}`
          );
        }
      }}
    >
      <Box
        h="26px"
        w="26px"
        background="#9CCDFB"
        b="none"
        borderRadius="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          padding="6px"
          paddingRight={isPadding && "10px"}
          paddingTop={isPadding && "10px"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src={img} alt={label} />
        </Box>
      </Box>
      <Box>
        <Text fontWeight={WEIGHT.regular} fontSize={"12px"} marginLeft="6px">
          {label}
        </Text>
      </Box>
    </Box>
  );
};

export default SmallIconLabel;
