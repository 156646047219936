import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import Box from "ReusableComponents/Box";
import Text from "ReusableComponents/Text";
import { SIZES } from "theme";

const StorePics = ({ title, subtitle, pics, small }) => {
  const images = pics?.data.map((item) => item?.attributes?.url);
  const imagesAltText = pics?.data.map(
    (item) => item?.attributes?.alternativeText
  );

  return (
    <Box
      textAlign="center"
      display="flex"
      margin={small && "20px 0"}
      flexDirection="column"
      padding={small ? 0 : "0 80px"}
    >
      <Text
        textColor="white"
        fontWeight="bold"
        fontSize={small ? SIZES.twentyFour : "40px"}
        margin="0"
      >
        {title}
      </Text>
      <Text
        textColor="#B8B8B8"
        fontSize={small ? SIZES.fourteen : "20px"}
        margin="24px 0"
      >
        {subtitle}
      </Text>
      {images?.length <= 6 && (
        <>
          <Grid contaier display="flex">
            <Grid item xs={8}>
              <Box padding="12px">
                <img
                  src={images[0]}
                  style={{ width: "100%", height: "100%" }}
                  alt={imagesAltText[0]}
                />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box padding="12px" borderRadius="10px">
                <img
                  src={images[1]}
                  style={{ width: "100%", height: "100%" }}
                  alt={imagesAltText[1]}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid contaier display="flex">
            <Grid item xs={4}>
              <Box padding="12px">
                <img
                  src={images[2]}
                  style={{ width: "100%", height: "100%" }}
                  alt={imagesAltText[2]}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box padding="12px">
                <img
                  src={images[3]}
                  style={{ width: "100%", height: "100%" }}
                  alt={imagesAltText[3]}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box padding="12px">
                <img
                  src={images[4]}
                  style={{ width: "100%", height: "100%" }}
                  alt={imagesAltText[4]}
                />
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default StorePics;
