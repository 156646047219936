import React, { useEffect } from "react";
import Box from "ReusableComponents/Box";
import IconHolder from "./IconHolder";
import { Grid, useMediaQuery } from "@mui/material";

const IconBox = ({ allfeatures, small }) => {
  const medium = useMediaQuery("(max-width: 900px)");

  return (
    <Box
      display="flex"
      margin={small || medium ? 0 : "20px "}
      space={2}
      justifyContent="center"
    >
      <Grid allfeatures xs={12} sm={6} md={3} key={2}>
        <IconHolder
          small={small}
          Icon={allfeatures?.attributes?.feature1image?.data?.attributes?.url}
          name={allfeatures?.attributes?.feature1}
        />
      </Grid>
      <Grid allfeatures xs={12} sm={6} md={3} key={3}>
        <IconHolder
          small={small}
          Icon={allfeatures?.attributes?.feature2image?.data?.attributes?.url}
          name={allfeatures?.attributes?.feature2}
        />
      </Grid>
    </Box>
  );
};

export default IconBox;
