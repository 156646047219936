export const COLORS = {
  white: "#ffffff",
  primary: "#99CEFB",
  secondary: "#000000",
  "03": "#F7F7F7",
  greyShade: "#707070",
  lightBlack: "#1C1C1C",
  gray: "#D3D3D3",
  lightGrey: "#B8B8B8",
  black: "#000000",
  newBackground: "#F5FAFF",
};

export const WEIGHT = {
  regular: "normal",
  bold: "bold",
};

export const SIZES = {
  zero: "0px",
  six: "6px",
  twelve: "12px",
  fourteen: "14px",
  sizteen: "16px",
  eighteen: "18px",
  twenty: "20px",
  twentyFour: "24px",
  thirtyTwo: "32px",
  forty: "40px",
  fortyFive: "45px",
  fortyEight: "48px",
  sixty: "60px",
  eighty: "80px",
};
