export const GET_BANNERS = "GET_BANNERS";
export const GET_FAQS = "GET_FAQS";
export const GET_TRUCKS = "GET_TRUCKS";
export const GET_TERMS = "GET_TERMS";
export const GET_REFUND = "GET_REFUND";
export const ACTIVE = "ACTIVE";
export const SHOW_PRODUCTS = "SHOW_PRODUCTS";
export const ADD_VIDEO = "ADD_VIDEO";
export const ADD_VIDEOS = "ADD_VIDEOS";
export const GET_FEATURE_IMAGES = "GET_FEATURE_IMAGES";
export const GET_COMPARISON_DATA = "GET_COMPARISON_DATA";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_TEAMS = "GET_TEAMS";
export const GET_CITIES = "GET_CITIES";
export const GET_STATES = "GET_STATES";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_TRANSACTION_DETAILS = "GET_TRANSACTION_DETAILS";
export const GET_DEALERSHIP_DETAILS = "GET_DEALERSHIP_DETAILS";
export const REMOVE_TRANSACTION_DETAILS = "REMOVE_TRANSACTION_DETAILS";
export const REMOVE_DEALERSHIP_DETAILS = "REMOVE_DEALERSHIP_DETAILS";
export const SAVE_SCREENS = "SAVE_SCREENS";
