import { Grid, LinearProgress, useMediaQuery } from "@mui/material";
import React, { lazy, useState, Suspense } from "react";
import { downloadBroucher } from "utils";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import ScrollToTop from "ReusableComponents/ScrollToTop";
import ShowroomNetwork from "Pages/ShowroomNetwork/ShowroomNetwork";
import ServicePage from "Pages/Service/Service";
import { useTranslation } from "react-i18next";
import withLanguageRedirect from "./withLanguageRedirect"; // Import the HOC
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Footer = lazy(() => import("Pages/Landing/components/Footer/Footer"));
const Header = lazy(() => import("Pages/Landing/components/Header/Header"));

const Landing = lazy(() => import("Pages/Landing"));
const Error404 = lazy(() => import("Pages/ErrorPages/Error404"));

const Careers = lazy(() => import("Pages/Careers/Careers"));
const Telematics = lazy(() => import("Pages/Telematics/Telematics"));
const Privacy = lazy(() => import("Pages/Policy/Pricvacy/Privacy"));
const Terms = lazy(() => import("Pages/Policy/Terms/Terms"));
const Refund = lazy(() => import("Pages/Policy/Refund/Refund"));
const ContactUs = lazy(() => import("Pages/ContactUs/ContactUs"));
const TestDrive = lazy(() => import("Pages/TestDrive/TestDrive"));
const TestDriveThankYou = lazy(() =>
  import("Pages/TestDrive/TestDriveThankYou/TestDriveThankyou")
);
const Booking = lazy(() => import("Pages/Booking/Booking"));
const BookingThankYou = lazy(() =>
  import("Pages/Booking/BookingThankYou/BookingThankYou")
);
const Dealership = lazy(() => import("Pages/Dealership/Dealership"));
const AboutUs = lazy(() => import("Pages/AboutUs/AboutUs"));
const Blogs = lazy(() => import("Pages/Blogs/Blogs"));
const Hiload = lazy(() => import("Pages/Hiload/Hiload"));
const Banner = lazy(() => import("Pages/components/Banner"));
const News = lazy(() => import("Pages/News/News"));
const ProductReview = lazy(() => import("Pages/ProductReview/ProductReview"));
const BlogPage = lazy(() => import("Pages/Blogs/components/BlogPage/BlogPage"));
const ChargingNetowrk = lazy(() =>
  import("Pages/ChargingNetwork/ChargingNetowrk")
);

const LinksToAvoidBanner = () => {
  if (
    window.location.href.includes("book") ||
    window.location.href.includes("ride") ||
    window.location.href.includes("terms") ||
    window.location.href.includes("policy") ||
    window.location.href.includes("refund") ||
    window.location.href.includes("blog")
  ) {
    return true;
  }
  return false;
};
const LanguageRedirect = ({ children }) => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language || "en";

  return (
    <Route
      path="*"
      render={({ location }) => {
        const { pathname } = location;
        const langMatch = pathname.match(/^\/(en|gu|hi|kn|mr|ta|te|bn)(\/|$)/);

        if (!langMatch) {
          return <Redirect to={`/${currentLang}${pathname}`} />;
        }

        return children;
      }}
    />
  );
};

const EnsureTrailingSlash = ({ children }) => {
  const { pathname } = useLocation();

  if (!pathname.endsWith("/")) {
    return <Redirect to={`${pathname}/`} />;
  }

  return children;
};

export const NavLinks = () => {
  const [active, setActive] = useState(0);
  const small = useMediaQuery("(max-width: 786px)");
  const medium = useMediaQuery("(max-width: 1024px)");
  const { i18n } = useTranslation();

  const handleActive = (index) => setActive(index);
  return (
    <Grid container spacing={2} sx={{ position: "relative" }}>
      <Suspense fallback={<LinearProgress />}>
        <Grid item xs={12} style={{ paddingLeft: 0, position: "relative" }}>
          <Header active={active} onClick={handleActive} />
        </Grid>
        <BrowserRouter>
          <LanguageRedirect>
            <EnsureTrailingSlash>
              <Switch>
                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/"
                  component={withLanguageRedirect(Landing)}
                />
                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/blogs"
                  component={withLanguageRedirect(Blogs)}
                />
                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/hiload"
                  component={withLanguageRedirect(Hiload)}
                />
                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/about-us"
                  component={withLanguageRedirect(AboutUs)}
                />
                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/charging-network"
                  component={withLanguageRedirect(ChargingNetowrk)}
                />
                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/vehicle-intelligence"
                  component={withLanguageRedirect(Telematics)}
                />
                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/policy"
                  component={withLanguageRedirect(Privacy)}
                />
                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/terms"
                  component={withLanguageRedirect(Terms)}
                />
                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/contact-us"
                  component={withLanguageRedirect(ContactUs)}
                />
                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/refund"
                  component={withLanguageRedirect(Refund)}
                />
                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/test-ride"
                  component={withLanguageRedirect(TestDrive)}
                />
                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/book"
                  component={withLanguageRedirect(Booking)}
                />
                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/dealership"
                  component={withLanguageRedirect(Dealership)}
                />
                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/blogs/:id"
                  component={withLanguageRedirect(BlogPage)}
                />
                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/news"
                  component={withLanguageRedirect(News)}
                />
                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/product-reviews"
                  component={withLanguageRedirect(ProductReview)}
                />
                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/career"
                  component={withLanguageRedirect(Careers)}
                />
                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/service-network"
                  component={withLanguageRedirect(ServicePage)}
                />
                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/showroom-locator"
                  component={withLanguageRedirect(ShowroomNetwork)}
                />

                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/thank-you-test-drive"
                  component={withLanguageRedirect(TestDriveThankYou)}
                />
                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/products/hiload"
                  component={() => <Redirect to="/hiload" />}
                />

                <Route
                  exact
                  path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/thank-you-booking"
                  component={BookingThankYou}
                />
                <Route
                  exact
                  path="/*"
                  component={withLanguageRedirect(Error404)}
                />
              </Switch>
            </EnsureTrailingSlash>
          </LanguageRedirect>
        </BrowserRouter>

        {!LinksToAvoidBanner() && (
          <Grid
            item
            xs={12}
            style={{ paddingTop: 0, background: "#9CCDFB", paddingLeft: 0 }}
          >
            <Banner
              downloadBroucher={downloadBroucher}
              small={small}
              medium={medium}
            />
          </Grid>
        )}

        <ScrollToTop />

        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Suspense>
    </Grid>
  );
};
