import React from "react";
import Box from "ReusableComponents/Box";
import Text from "ReusableComponents/Text";
import H2 from "ReusableComponents/Text/H2";
import { COLORS, WEIGHT } from "theme";

const IconHolder = ({ Icon, name, subName, small, hover }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
    >
      <Box
        h={small ? "80px" : "156px"}
        w={small ? "80px" : "156px"}
        background="#000000"
        borderRadius="50%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <img
            src={Icon}
            alt="icon"
            style={{
              height: "100%",
              opacity: hover ? 0.2 : 1,
            }}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" marginTop="27px">
        <H2
          fontSize={small ? "16px" : "24px"}
          fontWeight="bold"
          textColor={COLORS.white}
          margin={0}
          opacity={hover ? 0.2 : 1}
        >
          {name}
        </H2>
      </Box>
    </Box>
  );
};

export default IconHolder;
