/* eslint-disable import/no-anonymous-default-export */
import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import Logger from "redux-logger";
import thunk from "redux-thunk";
import { LandingReducer } from "store/reducers/Landing";

const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  Landing: LandingReducer,
});

const middlewares = [thunk];
if (process.env.NODE_ENV === "development") middlewares.push(Logger);

export const store = createStore(
  persistReducer(persistConfig, reducers),
  composeWithDevTools(applyMiddleware(...middlewares))
);

export const persistor = persistStore(store);
