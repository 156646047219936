import { COLORS, SIZES } from "theme";
import React, { lazy } from "react";

const Box = lazy(() => import("ReusableComponents/Box"));
const H1 = lazy(() => import("ReusableComponents/Text/H1"));
const Span = lazy(() => import("ReusableComponents/Text/Span"));

const ServiceHero = ({ bannerData, small }) => {
  return (
    <Box position="relative" h="560px">
      <Box
        display="flex"
        flexDirection="column"
        position="absolute"
        bottom="0"
        left="0"
        zIndex="2"
        h="-webkit-fill-available"
        w="-webkit-fill-available"
        justifyContent="flex-end"
      >
        <Box
          display="flex"
          flexDirection="column"
          margin={small ? "20px" : "20px 80px"}
        >
          <H1
            w="fit-content"
            display="flex"
            flexDirection="column"
            marginBottom={small ? SIZES.twelve : SIZES.twenty}
          >
            <Span
              fontSize={small ? SIZES.twentyFour : SIZES.forty}
              w="fit-content"
              textAlign="center"
              textColor="white"
              background={COLORS.black}
              fontWeight="bold"
              padding={small ? "0.2em" : "0.3em"}
            >
              {bannerData?.attributes?.heading}
            </Span>

            <Span
              fontSize={small ? SIZES.twentyFour : SIZES.forty}
              padding={small ? "0.2em" : "0.3em"}
              background={COLORS.black}
              textColor="white"
              w="fit-content"
              fontWeight="bold"
              textAlign="center"
              marginLeft="1em"
            >
              {bannerData?.attributes?.subheading}
            </Span>
          </H1>
        </Box>
      </Box>
      <img
        src={
          !small
            ? bannerData?.attributes?.media?.data?.attributes?.url
            : bannerData?.attributes?.media_mobile?.data?.attributes?.url
        }
        alt={
          bannerData?.attributes?.media_mobile?.data?.attributes
            ?.alternativeText
        }
        style={{ height: "100%", width: "100%", objectFit: "cover" }}
      />
    </Box>
  );
};

export default ServiceHero;
