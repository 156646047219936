import * as actionTypes from "store/types";
import { sortData } from "utils";

const initialState = {
  banners: [],
  screens: {
    extra_small: "0px",
    small: "600px",
    medium: "900px",
    large: "1200px",
    extra_large: "1536px",
  },
  featureImages: [],
  faq: [],
  trucks: [],
  terms: {
    media: "",
    terms: "",
  },
  refund: {
    media: "",
    refund: "",
  },
  active: 0,
  showProducts: false,
  video: {},
  comparison: {},
  products: [],
  teams: [],
  cities: [],
  states: [],
  locations: [],
  transactionDetails: {},
  dealershipDetails: {},
  videos: {},
};

export const LandingReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case actionTypes.GET_BANNERS: {
      return {
        ...state,
        banners: payload,
      };
    }
    case actionTypes.GET_FAQS: {
      return {
        ...state,
        faq: payload,
      };
    }
    case actionTypes.GET_TRUCKS: {
      return {
        ...state,
        trucks: payload,
      };
    }
    case actionTypes.ADD_VIDEOS: {
      return {
        ...state,
        videos: payload,
      };
    }
    case actionTypes.GET_TERMS: {
      return {
        ...state,
        terms: {
          ...state.terms,
          terms: payload.terms,
          media: payload.media,
        },
      };
    }
    case actionTypes.ACTIVE: {
      return {
        ...state,
        active: payload,
      };
    }
    case actionTypes.SHOW_PRODUCTS: {
      return {
        ...state,
        showProducts: payload,
      };
    }
    case actionTypes.ADD_VIDEO: {
      return {
        ...state,
        video: payload,
      };
    }
    case actionTypes.GET_FEATURE_IMAGES: {
      return {
        ...state,
        featureImages: sortData(payload),
      };
    }
    case actionTypes.GET_PRODUCTS: {
      return {
        ...state,
        products: payload,
      };
    }
    case actionTypes.GET_COMPARISON_DATA: {
      return {
        ...state,
        comparison: payload,
      };
    }
    case actionTypes.GET_REFUND: {
      return {
        ...state,
        refund: {
          ...state.refund,
          refund: payload.refund,
          media: payload?.media,
          alt: payload?.alt,
        },
      };
    }
    case actionTypes.GET_TEAMS: {
      return {
        ...state,
        teams: payload,
      };
    }
    case actionTypes.GET_CITIES: {
      return {
        ...state,
        cities: payload,
      };
    }
    case actionTypes.GET_STATES: {
      return {
        ...state,
        states: payload,
      };
    }
    case actionTypes.GET_LOCATIONS: {
      return {
        ...state,
        locations: payload,
      };
    }
    case actionTypes.GET_TRANSACTION_DETAILS: {
      return {
        ...state,
        transactionDetails: payload,
      };
    }
    case actionTypes.REMOVE_TRANSACTION_DETAILS: {
      return {
        ...state,
        transactionDetails: {},
      };
    }
    case actionTypes.GET_DEALERSHIP_DETAILS: {
      return {
        ...state,
        dealershipDetails: payload,
      };
    }
    case actionTypes.REMOVE_DEALERSHIP_DETAILS: {
      return {
        ...state,
        dealershipDetails: {},
      };
    }
    case actionTypes.SAVE_SCREENS: {
      return {
        ...state,
        screens: {
          small: payload.small,
          medium: payload.medium,
          large: payload.large,
        },
      };
    }
    default: {
      return state;
    }
  }
};
