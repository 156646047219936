import React from "react";
import Box from "ReusableComponents/Box";
import Text from "ReusableComponents/Text";
import H2 from "ReusableComponents/Text/H2";
import { COLORS, SIZES, WEIGHT } from "theme";
import IconBox from "./components/IconBox";

const ShowroomOptions = ({ allfeatures, small, medium }) => {
  return (
    <Box margin={small ? SIZES.forty : "80px"}>
      <Box textAlign="center">
        <Box display="flex" justifyContent="center" flexDirection="column">
          <H2
            fontSize={small ? SIZES.twenty : "40px"}
            fontWeight={WEIGHT.bold}
            textColor={COLORS.white}
            margin="0"
          >
            {allfeatures?.attributes?.heading}
          </H2>
          <Box w={medium || small ? "auto" : "760px"} margin="24px auto">
            <Text
              textColor={COLORS.lightGrey}
              fontSize={small ? "14px" : "20px"}
              fontWeight={WEIGHT.regular}
              lineHeight="1.5"
              margin="0"
            >
              {allfeatures?.attributes?.description}
            </Text>
          </Box>
        </Box>
        <Box>
          <IconBox allfeatures={allfeatures} small={small} />
        </Box>
      </Box>
    </Box>
  );
};

export default ShowroomOptions;
