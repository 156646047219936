import React, { useEffect, useState } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { imageListClasses, useMediaQuery } from "@mui/material";

export const GM = compose(
  withProps({
    /**
     * Note: create and replace your own key in the Google console.
     * https://console.developers.google.com/apis/dashboard
     * The key "AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q" can be ONLY used in this sandbox (no forked).
     */
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API}`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `72vh` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(({ chargingHubs = [], mapElement }) => {
  const small = useMediaQuery("(max-width: 600px)");
  const [center, setCenter] = useState({
    lat: "",
    lng: "",
  });
  useEffect(() => {
    if (chargingHubs?.length) {
      setCenter({
        lat: chargingHubs[chargingHubs.length - 1]?.attributes?.latitude,
        lng: chargingHubs[chargingHubs.length - 1]?.attributes?.longitude,
      });
    }
  }, [chargingHubs]);

  return (
    <GoogleMap
      defaultZoom={small ? 10 : 11}
      defaultCenter={{
        lat: 28.6289017,
        lng: 77.2065322,
      }}
      center={center}
    >
      {chargingHubs.map((item, i) => {
        return (
          <Marker
            position={{
              lat: item?.attributes?.latitude,
              lng: item?.attributes?.longitude,
            }}
            key={item?.hub_id}
          />
        );
      })}
      {/* <Marker position={{ lat: 28.677543, lng: 77.073407 }} /> */}
    </GoogleMap>
  );
});
