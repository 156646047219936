import { Grid, useMediaQuery } from "@mui/material";
import MetaTags from "ReusableComponents/MetaTags/MetaTags";
import Euler from "assets/euler.png";
import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";
import { apiClient } from "api/apiClient";
import _ from "lodash";
import { COLORS } from "theme";
import ShowroomNetworkHero from "./ShowroomNetworkHero";
import ShowroomOptions from "./ShowroomOptions";
import ShowroomLocation from "./ShowroomLocation";
import StoreLocator from "./StoreLocator";
import ShowroomAdvantage from "./ShowroomAdvantage";
import StorePics from "./StorePics";
import GalleryImageView from "./GalleryImageView";

const ShowroomNetwork = () => {
  const small = useMediaQuery("(max-width: 600px)");
  const medium = useMediaQuery("(max-width: 1200px)");
  const [bannerData, setBannerData] = useState([]);
  const [allfeatures, setAllFeatures] = useState([]);
  const [advantage, setadvantage] = useState([]);
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    apiClient.getStoreHero().then((res) => {
      setBannerData(res.data);
    });
    apiClient.getStoreFeature().then((res) => {
      setAllFeatures(res.data);
    });
    apiClient.getStoreAdvantage().then((res) => {
      setadvantage(res?.data);
    });
    apiClient.getStoreGallery().then((res) => {
      setGallery(res?.data);
    });
  }, []);
  return (
    <>
      {!_.isEmpty(bannerData) && (
        <Grid item xs={12}>
          <ShowroomNetworkHero bannerData={bannerData} small={small} />
        </Grid>
      )}
      <Grid item xs={12} style={{ background: COLORS.lightBlack }}>
        <ShowroomOptions
          allfeatures={allfeatures}
          small={small}
          medium={medium}
        />
      </Grid>

      <Grid item xs={12} style={{ padding: 0, background: COLORS.black }}>
        <ShowroomLocation small={small} medium={medium} />
      </Grid>

      <Grid
        item
        xs={12}
        style={
          small
            ? {
                paddingTop: "160px",
                paddingLeft: "20px",
                paddingRight: "10px",

                background: COLORS.black,
              }
            : { padding: "80px 0px", background: COLORS.black }
        }
      >
        <GalleryImageView pics={gallery} small={small} />
      </Grid>
      {/* <Grid
        item
        xs={12}
        style={{ padding: 0, zIndex: 1, background: COLORS.lightBlack }}
      >
        <ShowroomAdvantage
          small={small}
          medium={medium}
          advantage={advantage}
          text={[
            {
              id: 1,
              attributes: {
                order: 1,
                text: advantage?.attributes?.feature1description,
                createdAt: advantage?.attributes?.createdAt,
                updatedAt: advantage?.attributes?.updatedAt,
                locale: advantage?.attributes?.locale,
              },
            },
            {
              id: 2,
              attributes: {
                order: 2,
                text: advantage?.attributes?.feature2description,
                createdAt: advantage?.attributes?.createdAt,
                updatedAt: advantage?.attributes?.updatedAt,
                locale: advantage?.attributes?.locale,
              },
            },
            {
              id: 3,
              attributes: {
                order: 3,
                text: advantage?.attributes?.feature3description,
                createdAt: advantage?.attributes?.createdAt,
                updatedAt: advantage?.attributes?.updatedAt,
                locale: advantage?.attributes?.locale,
              },
            },
          ]}
        />
      </Grid> */}

      <Helmet>
        <title>Electric Vehicle Showroom Network | Euler Motors</title>
        <link
          rel="publisher"
          href="https://www.eulermotors.com/charging-network"
        />
        <meta
          name="title"
          content="Electric Vehicle Showroom Network | Euler Motors"
        />
        <meta
          name="description"
          content="Concerned about finding charging stations or charging hubs for your electric three wheelers? With Euler Motors EV charging solutions, charge your vehicle at the convenience of your home or on the go."
        />
        ​
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.eulermotors.com/showroom-network"
        />
        <meta
          property="og:title"
          content="Electric Vehicle Showroom Network | Euler Motors"
        />
        <meta
          property="og:description"
          content="Concerned about finding charging stations or charging hubs for your electric three wheelers? With Euler Motors EV charging solutions, charge your vehicle at the convenience of your home or on the go."
        />
        <meta property="og:image" content={Euler} />
        ​
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.eulermotors.com/showroom-network"
        />
        <meta
          property="twitter:title"
          content="Electric Vehicle Showroom Network | Euler Motors"
        />
        <meta
          property="twitter:description"
          content="Concerned about finding charging stations or charging hubs for your electric three wheelers? With Euler Motors EV charging solutions, charge your vehicle at the convenience of your home or on the go."
        />
        <meta property="twitter:image" content={Euler} />
        <MetaTags link={"https://www.eulermotors.com/showroom-network"} />
      </Helmet>
    </>
  );
};

export default ShowroomNetwork;
